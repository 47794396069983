import  { useState, useEffect } from "react";
import { Table } from "reactstrap";
import * as actions from "./../../../Redux/Actions";
import useLoadingIndicator from "../../../component/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
} from "./../../../component/Toasts/Toasts";
import moment from "moment";
import "./EmploymentHistory.scss";
import {
  Strings,
  employmentHistoryHeader,
  workAchievementsHeader,
  requiredFeild,
} from "../../../constant/Strings";
import Button from "../../../component/Buttons/Button";
import ModalComponents from "../../../component/ModalComponent/ModalComponent";
import DeleteExperiences from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Experiences/DeleteExperiences";
import EditExperiences from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Experiences/EditExperiences";
import AddExperiences from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/Experiences/AddExperiences";
import DeleteWorkAchievements from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/WorkAchievements/DeleteWorkAchievements";
import AddWorkAchievements from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/WorkAchievements/AddWorkAchievements";
import EditWorkAchievements from "../../EmployeeDb/EmployeeDetails/DbSideBarPages/WorkAchievements/EditWorkAchievements";
import { icons } from "../../../assets";

const EmploymentHistory = (props: any) => {
  const { status, isFresher,getApiDispatch } = props;
  const { loadingComponent, showLoadingIndicator, hideLoadingIndicator } =
    useLoadingIndicator();
  const [info, setInfo] = useState([]);
  const [experienceInfo, setExperienceInfo] = useState([]);
  const [showAddExperiences, setShowAddExperiences] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const { required } = requiredFeild;
  const [experiences, setExperiences] = useState({
    id: "",
    companyName: "",
    designation: "",
    location: "",
    rolesAndResponsibility: "",
    startDate: "",
    endDate: "",
    relievingLetter: "",
    paySlip: "",
    resignationAcceptanceLetter: "",
    lastEmployer: "",
  });

  const userLogin: any = localStorage.getItem("login");
  const loginId = JSON.parse(userLogin)?.id;
  const role = localStorage.getItem("role");
  const selectedId = localStorage.getItem("selectedId");
  const dispatch = useDispatch();
  const handleEdit = (isFresher: any) => {
    showLoadingIndicator();
    dispatch(
      actions.editEmployeeDataAction(
        { isFresher: isFresher, updatedBy: loginId, eId: selectedId },
        (res: any) => {
              if (res?.error?.error_description) {
                showErrorToastMessage(res.error.error_description);
              } else if(res.code === 200) {
                showSuccessToastMessage(editSuccess);
                hideLoadingIndicator();
          }
        }
      )
    );
  };

  const handleAddExperiences = () => setShowAddExperiences(true);
  const hideAddExperiences = () => setShowAddExperiences(false);

  const [showEditExperiences, setShowEditExperiences] = useState(false);
  const handleEditExperiences = (
    id: any,
    companyName: any,
    designation: any,
    location: any,
    rolesAndResponsibility: any,
    startDate: any,
    endDate: any,
    relievingLetter: any,
    paySlip: any,
    resignationAcceptanceLetter: any,
    lastEmployer: any
  ) => {
    setExperiences({
      id: id,
      companyName: companyName,
      designation: designation,
      location: location,
      rolesAndResponsibility: rolesAndResponsibility,
      startDate: startDate,
      endDate: endDate,
      relievingLetter: relievingLetter,
      paySlip: paySlip,
      resignationAcceptanceLetter: resignationAcceptanceLetter,
      lastEmployer: lastEmployer,
    });
    setShowEditExperiences(true);
  };
  const hideEditExperiences = () => setShowEditExperiences(false);
  const [showDeleteExperiences, setShowDeleteExperiences] = useState(false);
  const handleDeleteExperiences = (id: any) => {
    setDeleteId(id);
    setShowDeleteExperiences(true);
  };
  const hideDeleteExperiences = () => setShowDeleteExperiences(false);

  const addEmployeeWorkAchievements = useSelector(
    (state: any) => state.employeeReducer.addEmployeeWorkAchievements
  );
  const deleteEmployeeWorkAchievements = useSelector(
    (state: any) => state.employeeReducer.deleteEmployeeWorkAchievements
  );
  const editEmployeeWorkAchievements = useSelector(
    (state: any) => state.employeeReducer.editEmployeeWorkAchievements
  );

  const dispatchWorkAchievements = useDispatch();
  useEffect(() => {
    showLoadingIndicator();
    dispatchWorkAchievements(actions.getEmployeeWorkAchievementsAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [
    addEmployeeWorkAchievements,
    editEmployeeWorkAchievements,
    deleteEmployeeWorkAchievements,
    selectedId,
  ]);

  const employeeWorkAchievements = useSelector(
    (state: any) => state.employeeReducer.getEmployeeWorkAchievements
  );
  useEffect(() => {
    if (employeeWorkAchievements) {
      if (employeeWorkAchievements.data) {
        setInfo(employeeWorkAchievements.data);
      }
    }
  }, [employeeWorkAchievements, info]);

  const addExperience = useSelector(
    (state: any) => state.employeeReducer.addExperienceData
  );
  const editExperience = useSelector(
    (state: any) => state.employeeReducer.editExperienceData
  );
  const deleteExperience = useSelector(
    (state: any) => state.employeeReducer.deleteExperienceData
  );

  useEffect(() => {
    showLoadingIndicator();
    dispatch(actions.getEmployeeExperienceAction());
    setTimeout(() => {
      hideLoadingIndicator();
    }, 1000);
  }, [addExperience, editExperience, deleteExperience, selectedId]);

  const experienceData = useSelector(
    (state: any) => state.employeeReducer.getExperienceData
  );
  useEffect(() => {
    if (experienceData) {
      if (experienceData.data) {
        setExperienceInfo(experienceData.data);
      }
    }
  }, [experienceData, info]);

  const [workAchievements, setWorkAchievements] = useState({
    id: "",
    award: "",
    description: "",
    date: "",
    certificate: "",
  });
  const [workDeleteId, setWorkDeleteId] = useState("");

  const [showAddWorkAchievements, setShowAddWorkAchievements] = useState(false);

  const handleAddWorkAchievements = () => setShowAddWorkAchievements(true);
  const hideAddWorkAchievements = () => {
    setShowAddWorkAchievements(false);
  };

  const [showEditWorkAchievements, setShowEditWorkAchievements] =
    useState(false);
  const handleEditWorkAchievements = (
    id: string,
    award: any,
    description: any,
    date: any,
    certificate: any
  ) => {
    setWorkAchievements({
      id: id,
      award: award,
      description: description,
      date: date,
      certificate: certificate,
    });
    setShowEditWorkAchievements(true);
  };
  const hideEditWorkAchievements = () => {
    setShowEditWorkAchievements(false);
  };

  const [showDeleteWorkAchievements, setShowDeleteWorkAchievements] =
    useState(false);
  const handleDeleteWorkAchievements = (id: any) => {
    setWorkDeleteId(id);
    setShowDeleteWorkAchievements(true);
  };
  const hideDeleteWorkAchievements = () => {
    setShowDeleteWorkAchievements(false);
  };
  const Statuscondition =
    (status !== "Active" && status !== "Inactive") ||
    (role &&
      role.includes("hr") &&
      status === "Active" &&
      selectedId != loginId);

  const {
    employment_history_heading,
    work_achievements_heading,
    editSuccess,
    clickFresher,
  } = Strings;
  const { edit_blue_icon, delete_icon } = icons;
  const Checked = (event: any) => {
    if (event?.target.checked) {
      handleEdit(1);
    } else {
      handleEdit(0);
    }
  };
  return (
    <>
      {loadingComponent()}
      <div className="emphistory-container">
        {experienceInfo.length <= 0 && Statuscondition && (
          <div className="d-flex fresher-checkbox">
            <input
              className="checkbox-fresher"
              type="checkbox"
              id="checkBox"
              checked={isFresher}
              onClick={(e: any) => Checked(e)}
            />{" "}
            <div className="mx-2">{clickFresher}</div>
          </div>
        )}
        <div className="heading-button-wrapper mt-4">
          <div>
            <h5>
              {employment_history_heading}{" "}
              {!isFresher && (
                <>
                  <span className="required">{required}</span>
                </>
              )}
            </h5>
          </div>

          {Statuscondition && isFresher == 0 ? (
            <div className="btn-wrapper">
              <Button
                title="+ Add"
                onClick={handleAddExperiences}
                className="add-employee-btn add-employee-details-skills"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="card-div-container">
          <Table className="table-conatiner" cellSpacing={2} cellPadding={20}>
            <thead className="table-head">
              {employmentHistoryHeader.map((item, key) => (
                <td className="table-td" key={key}>
                  {item}
                </td>
              ))}
              {Statuscondition ? <td className="table-td">Action</td> : <></>}
            </thead>
            {isFresher == 0 ? (
              <tbody className="table-body">
                {experienceInfo.map((item: any, key) => {
                  return (
                    <tr key={key}>
                      <td className="table-td">{item.companyName}</td>
                      <td className="table-td">{item.designation}</td>
                      <td className="table-td">{item.location}</td>
                      <td className="table-td">
                        {item.rolesAndResponsibility}
                      </td>
                      <td className="table-td">
                        {moment(item.startDate).format("DD/MMM/YYYY")}
                      </td>
                      <td className="table-td">
                        {moment(item.endDate).format("DD/MMM/YYYY")}
                      </td>
                      {Statuscondition ? (
                        <>
                          <td className="table-td">
                            <div className="text-center">
                              <img
                                src={edit_blue_icon}
                                alt="edit_icon"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                                onClick={() =>
                                  handleEditExperiences(
                                    item.id,
                                    item.companyName,
                                    item.designation,
                                    item.location,
                                    item.rolesAndResponsibility,
                                    item.startDate,
                                    item.endDate,
                                    item.relievingLetter,
                                    item.paySlip,
                                    item.resignationAcceptanceLetter,
                                    item.lastEmployer
                                  )
                                }
                                className="edit-icon"
                              />
                              <img
                                src={delete_icon}
                                alt="delete-icon"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                onClick={() => {
                                  handleDeleteExperiences(item.id);
                                }}
                                className="edit-icon"
                              />
                            </div>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody className="employeedata">
                <td colSpan={12} className="fresher-no-data">
                  Fresher - No Data
                </td>
              </tbody>
            )}
          </Table>
        </div>

        <div className="heading-button-wrapper">
          <div>
            <h5>{work_achievements_heading}</h5>
          </div>
          {Statuscondition ? (
            <div className="btn-wrapper">
              <Button
                title="+ Add"
                onClick={handleAddWorkAchievements}
                className="add-employee-btn add-employee-details-skills"
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="card-div-container">
          <Table className="table-conatiner" cellSpacing={2} cellPadding={20}>
            <thead className="table-head">
              {workAchievementsHeader.map((item, key) => (
                <td className="table-td" key={key}>
                  {item}
                </td>
              ))}
              {Statuscondition ? <td className="table-td">Action</td> : <></>}
            </thead>
            <tbody className="table-body">
              {info.map((item: any, key) => {
                return (
                  <tr key={key}>
                    <td className="table-td">{item.award}</td>
                    <td className="table-td">{item.description}</td>
                    <td className="table-td">
                      {moment(item.date).format("DD/MMM/YYYY")}
                    </td>
                    {Statuscondition ? (
                      <>
                        <td className="table-td">
                          <div className="text-center">
                            <img
                              src={edit_blue_icon}
                              alt="edit_icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              onClick={() => {
                                handleEditWorkAchievements(
                                  item.id,
                                  item.award,
                                  item.description,
                                  item.date,
                                  item.certificate
                                );
                              }}
                              className="edit-icon"
                            />
                            <img
                              src={delete_icon}
                              alt="delete-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                              onClick={() =>
                                handleDeleteWorkAchievements(item.id)
                              }
                              className="edit-icon"
                            />
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <ModalComponents
        show={showAddExperiences}
        onHide={hideAddExperiences}
        title="Add Employment History"
        modalBody={<AddExperiences hideAddExperiences={hideAddExperiences} data={experienceInfo} getApiDispatch={getApiDispatch}/>}
        className="modal-lg"
      />

      <ModalComponents
        show={showEditExperiences}
        onHide={hideEditExperiences}
        title="Edit Employment History"
        modalBody={
          <EditExperiences
            id={experiences.id}
            companyName={experiences.companyName}
            designation={experiences.designation}
            location={experiences.location}
            rolesAndResponsibility={experiences.rolesAndResponsibility}
            startDate={experiences.startDate}
            endDate={experiences.endDate}
            relievingLetter={experiences.relievingLetter}
            paySlip={experiences.paySlip}
            resignationAcceptanceLetter={
              experiences.resignationAcceptanceLetter
            }
            hideEditExperiences={hideEditExperiences}
            lastEmployer={experiences.lastEmployer}
            data={experienceInfo}
            getApiDispatch={getApiDispatch}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showDeleteExperiences}
        onHide={hideDeleteExperiences}
        title="Delete Employment History"
        modalBody={
          <DeleteExperiences
            id={deleteId}
            hideDeleteExperiences={hideDeleteExperiences}
            getApiDispatch={getApiDispatch}
          />
        }
        className="modal-md"
      />

      <ModalComponents
        show={showAddWorkAchievements}
        onHide={hideAddWorkAchievements}
        title="Add WorkAchievement"
        modalBody={
          <AddWorkAchievements
            showAddSkill={handleAddWorkAchievements}
            hideAddWorkAchievements={hideAddWorkAchievements}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showEditWorkAchievements}
        onHide={hideEditWorkAchievements}
        title="Edit WorkAchievement"
        modalBody={
          <EditWorkAchievements
            id={workAchievements.id}
            award={workAchievements.award}
            description={workAchievements.description}
            date={workAchievements.date}
            certificate={workAchievements?.certificate}
            hideEditWorkAchievements={hideEditWorkAchievements}
          />
        }
        className="modal-lg"
      />

      <ModalComponents
        show={showDeleteWorkAchievements}
        onHide={hideDeleteWorkAchievements}
        title="Delete WorkAchievement"
        modalBody={
          <DeleteWorkAchievements
            id={workDeleteId}
            hideDeleteWorkAchievements={hideDeleteWorkAchievements}
          />
        }
        className="modal-md"
      />
    </>
  );
};

export default EmploymentHistory;
